<template>
  <div class="ui-infinite-scroll">
    <ui-loading label="Cargando.."></ui-loading>
  </div>
</template>

<script>
import { UiLoading } from '@/modules/ui/components';
export default {
  name: 'ui-infinite-scroll',
  components: { UiLoading },

  data() {
    return {
      IntersectionObserver: null,
    };
  },

  methods: {
    callbackObserver(entries, observer) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.$emit('input', entries);
        }
      });
    },
  },

  created() {
    this.IntersectionObserver = new IntersectionObserver(
      this.callbackObserver,
      {
        root: this.$el,
        threshold: 1.0,
      }
    );
  },

  mounted() {
    this.IntersectionObserver.observe(this.$el);
  },

  beforeDestroy() {
    this.IntersectionObserver.disconnect();
  },
};
</script>
<style lang="scss" >
.ui-infinite-scroll {
  height: 100px;
}
</style>